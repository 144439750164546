<template>
  <b-card>
    <b-row class="d-flex justify-content-end">
      <b-col class="d-flex justify-content-end">
        <WarehouseOperations ref="flow-warehouses-event-handler" />
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('CARICO')"
        >
          Carica
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('SCARICO')"
        >
          Scarica
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('SPOSTA')"
        >
          Sposta
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn"
          @click="selectedOp('RETTIFICA')"
        >
          Rettifica
        </b-button>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col
        cols="4"
        xl="2"
      >
        <b-form-group
          label="Scorta Minima"
          label-for="scorta-minima"
        >
          <b-form-input
            id="scorta-minima"
            name="scorta-minima"
            placeholder="0.5, 1, 10, 100"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
        xl="2"
      >
        <b-form-group
          label="Scorta Massima"
          label-for="scorta-max"
        >
          <b-form-input
            id="scorta-max"
            name="scorta-max"
            placeholder="0.5, 1, 10, 100"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
        xl="2"
      >
        <b-form-group
          label="Riordino minimo"
          label-for="minimun-re-order"
        >
          <b-form-input
            id="minimun-re-order"
            name="minimun-re-order"
            placeholder="0.5, 1, 10, 100"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col>
        <h4>
          Quantità magazzino: {{ warehouseData.quantity }}
        </h4>
        <h4>
          Data Primo Carico: 2021/13/01
        </h4>
        <h4>
          Data Ultimo Carico: 2022/10/01
        </h4>
        <h4>
          Data Primo Scarico: 2021/13/01
        </h4>
        <h4>
          Data Ultimo Scarico: 2022/10/01
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="d-flex flex-row justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          type="submit"
        >
          Salva
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BButton, BCard, VBToggle, // BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import WarehouseOperations from './sidebarEventHandler/WarehouseOperations.vue'
import WarehouseOperations from '@/views/pages/portfolio/products/details/sidebarEventHandler/WarehouseOperations.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    /* BFormInput,
    BFormGroup, */
    WarehouseOperations,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    warehouseData: { type: Object, required: true },
  },
  data() {
    return {
      selectedOperation: '',
    }
  },
  methods: {
    /* selectedOp(operation) {
      this.$refs['warehouse-operations'].setTitleOperation(operation)
      this.selectedOperation = operation
    }, */
    manageWarehouse({ operation, value }) {
      if (operation === 'Carica') {
        this.warehouseData.quantity += value
      } else if (operation === 'Scarica') {
        this.warehouseData.quantity -= value
      } else if (operation === 'Sposta') {
        this.warehouseData.quantity -= value
      } else if (operation === 'Rettifica') {
        this.warehouseData.quantity = value
      }
    },
    selectedOp(operation) {
      this.$refs['flow-warehouses-event-handler'].setTitle(operation)
      this.$refs['flow-warehouses-event-handler'].openSidebar()

      // this.selectedOperation = operation
    },
  },
}
</script>

<style>
.myBtn{
  width: 105px;
}
</style>
