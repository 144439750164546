<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <!-- caratteristiche {{ productCaratteristiche }} -->
    <b-form>
      <div v-if="this.$store.state.products.productGeneral.tipoProdotto.id === 2">
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <div class="d-flex flex-column">
              <b-form-group
                label="Data Inizio Servizio"
                label-for="product-start-date"
                label-class="font-weight-bolder"
              >
                <flat-pickr
                  id="product-start-date"
                  v-model="productCaratteristiche.dataInizioServizio"
                  class="form-control"
                  name="birth-date"
                  :config="configFlatPicker"
                  :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                />
              </b-form-group>
              <b-form-group
                label="Data Inizio Supporto"
                label-for="product-start-support"
                label-class="font-weight-bolder"
              >
                <flat-pickr
                  id="product-start-support"
                  v-model="productCaratteristiche.dataInizioSupporto"
                  class="form-control"
                  name="birth-date"
                  :config="configFlatPicker"
                  :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                />
              </b-form-group>
              <b-form-group
                label="Data Inizio Prenotazione"
                label-for="product-start-date-reservation"
                label-class="font-weight-bolder"
              >
                <flat-pickr
                  id="product-start-date-reservation"
                  v-model="productCaratteristiche.dataInizioPrenotazione"
                  class="form-control"
                  name="birth-date"
                  :config="configFlatPicker"
                  :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <div class="d-flex flex-column">
              <b-form-group
                label="Data Fine Servizio"
                label-for="product-start-date"
                label-class="font-weight-bolder"
              >
                <flat-pickr
                  id="product-end-date"
                  v-model="productCaratteristiche.dataFineServizio"
                  class="form-control"
                  name="birth-date"
                  :config="configFlatPicker"
                  :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                />
              </b-form-group>
              <b-form-group
                label="Data Fine Supporto"
                label-for="product-end-date-support"
                label-class="font-weight-bolder"
              >
                <flat-pickr
                  id="product-end-date-support"
                  v-model="productCaratteristiche.dataFineSupporto"
                  class="form-control"
                  name="birth-date"
                  :config="configFlatPicker"
                  :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                />
              </b-form-group>
              <b-form-group
                label="Data Fine Prenotazione"
                label-for="product-end-date-reservation"
                label-class="font-weight-bolder"
              >
                <flat-pickr
                  id="product-end-date-reservation"
                  v-model="productCaratteristiche.dataFinePrenotazione"
                  class="form-control"
                  name="birth-date"
                  :config="configFlatPicker"
                  :placeholder="$t('personalProfile.information.placeholder.dateBirth')"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row
        v-if="this.$store.state.products.productGeneral.tipoProdotto.id === 1"
        class="d-flex flex-column"
      >
        <h3 class="ml-1">
          Dimensioni Fisiche
        </h3>
        <b-col
          cols="6"
          lg="3"
          class=""
        >
          <b-form-group
            :label="'Larghezza'"
            :label-for="'LarghezzaProdotto'"
            label-class="font-weight-bolder"
          >
            <b-input-group append="cm">
              <b-form-input
                :id="'LarghezzaProdotto'"
                v-model="productCaratteristiche.larghezza"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          lg="3"
        >
          <b-form-group
            :label="'Lunghezza'"
            :label-for="'LarghezzaProdotto'"
            label-class="font-weight-bolder"
          >
            <b-input-group append="cm">
              <b-form-input
                :id="'LarghezzaProdotto'"
                v-model="productCaratteristiche.lunghezza"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          lg="3"
        >
          <b-form-group
            :label="'Profondità'"
            :label-for="'ProfonditaProdotto'"
            label-class="font-weight-bolder"
          >
            <b-input-group append="cm">
              <b-form-input
                :id="'ProfonditaProdotto'"
                v-model="productCaratteristiche.profondita"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          lg="3"
        >
          <h3>Peso</h3>
          <b-form-group
            :label="'Netto'"
            :label-for="'Peso-Netto'"
            label-class="font-weight-bolder"
          >
            <b-input-group append="KG">
              <b-form-input
                :id="'Peso-Netto'"
                v-model="productCaratteristiche.pesoNetto"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          lg="3"
        >
          <b-form-group
            :label="'Lordo'"
            :label-for="'Peso-Netto'"
            label-class="font-weight-bolder"
          >
            <b-input-group append="KG">
              <b-form-input
                :id="'Peso-Netto'"
                v-model="productCaratteristiche.pesoLordo"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="this.$store.state.products.productGeneral.tipoProdotto.id === 3">
        <b-row>
          <b-col cols="12">
            <p><b>Qui potrai associare al tuo prodotto unificato, uno o più prodotti. Ricorda che potrai associare un prodotto ad un solo prodotto unificato</b></p>
          </b-col>
          <!-- <b-col class="d-flex justify-content-end mb-2">
            <b-button
              variant="primary"
              size="sm"
              @click="selectedProductCorrelate('Aggiungi prodotti Unificati')"
            >
              Aggiungi Nuovo
            </b-button>
          </b-col> -->
        </b-row>
        <!-- <div>
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>
                <strong>
                  Non hai inserito nessun prodotto correlato
                </strong>
              </span>
            </div>
          </b-alert>
        </div> -->
        <!-- {{ productCaratteristiche }} -->
        <b-row class="">
          <b-col cols="12">
            <ejs-grid
              id="overviewgridprodottocaratteristiche"
              ref="overviewgridprodottocaratteristiche"
              locale="it-IT"
              :data-source="productCaratteristiche.prodottoAssociati"
              :selection-settings="selectionSettings"
              :edit-settings="editSettings"
              :allow-filtering="true"
              :allow-editing="false"
              :filter-settings="filterOptions"
              :show-column-chooser="true"
              :allow-selection="true"
              :allow-sorting="true"
              :allow-resizing="true"
              :enable-virtualization="false"
              :allow-row-drag-and-drop="false"
              height="500"
              row-height="38"
              :enable-hover="false"
              :toolbar-click="toolbarClick"
              :enable-header-focus="true"
              :toolbar="toolbar"
              :allow-grouping="false"
              :allow-paging="true"
              :page-settings="pageSettings"
            >
              <e-columns>
                <e-column
                  type="checkbox"
                  :allow-filtering="false"
                  :allow-sorting="false"
                  width="70"
                />
                <e-column
                  field="id"
                  header-text="id"
                  :is-primary-key="true"
                  width="200"
                  :visible="false"
                  :allow-editing="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="codice"
                  header-text="Codice"
                  width="100"
                  :filter="filter"
                  :allow-editing="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="nome"
                  header-text="Nome"
                  width="200"
                  :filter="filter"
                  :allow-editing="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="tipoProdotto"
                  header-text="Tipologia"
                  width="140"
                  :filter="filter"
                />
                <e-column
                  field="quantita"
                  header-text="QNT"
                  width="120"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="prezzo"
                  header-text="Prezzo"
                  width="120"
                  clip-mode="EllipsisWithTooltip"
                  :visible="false"
                />
                <e-column
                  field="costo"
                  header-text="Costo"
                  width="120"
                  :allow-editing="false"
                  clip-mode="EllipsisWithTooltip"
                  :visible="false"
                />
                <e-column
                  field="Azioni"
                  :lock-column="true"
                  header-text="Azioni"
                  width="150"
                  :allow-editing="false"
                  :commands="commands"
                  :allow-sorting="false"
                  :allow-filtering="false"
                  hide-at-media="(min-width:900px)"
                />
              </e-columns>
            </ejs-grid>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col
          cols="12"
          class="d-flex flex-row justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="submitChanges"
          >
            Salva
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
      <SidebarProductEventHandlerInDetailsProdcut
        ref="sidebar-product-event-handler-in-detailts-product"
        @add-selected-product="addSelectProduct"
      />
    </b-form>
  </b-card>
</template>

<script>
import Vue from 'vue'
import {
  BRow, BCol, BButton, BCard, BFormGroup, BFormInput, BInputGroup, VBToggle, BForm, // BAlert,
} from 'bootstrap-vue'
import {
  GridPlugin, Sort, Filter, Selection, Toolbar, Edit, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate, Page,
} from '@syncfusion/ej2-vue-grids'
import flatPickr from 'vue-flatpickr-component'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
// import vSelect from 'vue-select'
// import axios from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import Ripple from 'vue-ripple-directive'
import { setProdottoCaratteristiche } from '@/@core/api-service/catalog/products'
import SidebarProductEventHandlerInDetailsProdcut from '@/components/sidebar/SidebarProductEventHandlerInDetailsProdcut.vue'
import store from '@/store/index'

Vue.use(ToastPlugin)
Vue.use(GridPlugin)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    // BTable,
    BFormInput,
    BInputGroup,
    BButton,
    // vSelect,
    flatPickr,
    // BAlert,
    BForm,
    SidebarProductEventHandlerInDetailsProdcut,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      prodottiAssociati: [],
      productCorrelated: [],
      commands: [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
        { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
      ],
      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'CheckBox',
      },
      selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal',
      },
      toolbar: ['Delete', 'ColumnChooser', 'Print', {
        text: 'Aggiungi Prodotto', tooltipText: 'Aggiungi Prodotto', prefixIcon: 'e-expand', id: 'addProduct',
      }],
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Edit', 'Delete', 'Save', 'Cancel',
        'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup',
      ],
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 200], pageCount: 5 },
      selectedRows: [],
      searchTerm: '',
      pageLength: 10,
      unifiedProducts: [],
      stateService: ['Sviluppo', 'Operativo', 'Ritirato'],
      defaultDate: '2021-02-20',
      configFlatPicker: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'Y-m-d',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
      },
    }
  },
  mounted() {
  },
  computed: {
    getTradeData() {
      return this.productCorrelated
    },
    productCaratteristiche() {
      return this.$store.getters['products/getProductCaratteristiche']
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        Active       : 'light-success',
        Inactive     : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    typeProduct() {
      // console.log('1', this.$store.state.products.typeProduct.codice)
      return this.$store.state.products.typeProduct
    },
  },
  methods: {
    toolbarClick(args) {
      // console.log('toolbarClick', args)
      if (args.item.id === 'addProduct') {
        this.$refs['sidebar-product-event-handler-in-detailts-product'].openSidebar()
      }
    },
    addSelectProduct(products) {
      // const productsActual = [...this.$refs.overviewgridprodottocaratteristiche.ej2Instances.dataSource]
      // console.log('attuali', productsActual)
      // productsActual.unshift(...products)
      products.forEach(product => {
        const indexFound = this.$refs.overviewgridprodottocaratteristiche.getRowIndexByPrimaryKey(Number(product.id))
        // console.log('indexFound', indexFound)
        if (indexFound < 0) {
          // this.productCorrelated.unshift(...product)
          this.$refs.overviewgridprodottocaratteristiche.addRecord(product, 0)
          // this.productCorrelated.push(product)
        }
      })
      // console.log('attuali unshift', productsActual)
      // this.$refs.overviewgridprodottocaratteristiche.ej2Instances.dataSource = this.productCorrelated
    },
    submitChanges() {
      // console.log('this.productCorrelated', this.productCorrelated)
      // const associati = this.productCorrelated.map(el => ({ id: el.id }))
      this.productCaratteristiche.associati = this.$refs.overviewgridprodottocaratteristiche.getDataModule()
      this.productCaratteristiche.associati = this.productCaratteristiche.associati.dataManager.dataSource.json
      // console.log(this.productCaratteristiche)
      // console.log('changes', this.$store.getters['products/getProductGeneral'])
      setProdottoCaratteristiche(JSON.stringify([{ id: this.$store.getters['products/getProductGeneral'].id, ...this.productCaratteristiche }]))
        .then(res => {
          if (res.data.esito === 'OK') {
            this.$refs.toastRef.show({
              title: 'Operazione Completata!', content: 'Prodotto/i Modificato/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
            })
          } else if (res.data.esito === 'KO') {
            this.$refs.toastRef.show({
              title: 'Errore!', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
            })
          }
        }).catch(e => e)
    },
    addToGroup(data) {
      // console.log(data)
      this.unifiedProducts.push(...data)
    },
    selectedProductCorrelate() {
      this.$refs['sidebar-product-event-handler-in-detailts-product'].openSidebar()
    },
    showDetails(params) {
      this.$router.push({ name: 'details-products', params: { id: params.row.id } })
    },
    showDetailsFromButton(row) {
      this.$router.push({ name: 'details-products', params: { id: row.id } })
    },
    selectionChanged(params) {
      // console.log(params)
      if (params.selectedRows.length !== 0) {
        this.selectedRows = [...params.selectedRows]
        // this.showButtonsOption = true
        return false
      }
      // this.showButtonsOption = false
      return false
    // params.selectedRows - all rows that are selected (this page)
    },
    deleteRow() {
      // console.log(this.selectedRows)
      const myArrayFake = [...this.unifiedProducts]
      this.unifiedProducts.forEach((element, index) => {
        this.selectedRows.forEach(el => {
          if (element.id === el.id) {
            myArrayFake.splice(index, 1)
          }
        })
      })
      this.unifiedProducts.length = 0
      this.unifiedProducts = [...myArrayFake]
    },
  },
  provide: {
    grid: [Toolbar, Filter, Selection, Sort, ColumnChooser, Resize, Scroll, Aggregate, Edit, ContextMenu, CommandColumn, Page],
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
