<template>
  <div>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-modal
      id="add-productc-code"
      ref="add-productc-code"
      ok-only
      ok-title="Aggiungi"
      centered
      no-close-on-backdrop
      title="Aggiungi Cod"
      size="lg"
      @ok="addCode"
      @close="resetModal"
    >
      <!-- <p>{{ dizionariCode }}</p> -->
      <validation-observer ref="addCodProductForm">
        <b-form id="form-code">
          <b-row
            style="height: 300px !important"
          >
            <b-col>
              <b-form-group
                label="Tipo Codice"
                label-for="product-code-type-add"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tipo Codice"
                  rules="required"
                >
                  <v-select
                    id="product-code-type-add"
                    v-model="formObject.type"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descrizione"
                    :reduce="cod => ({ codice: cod.codice, id: cod.id })"
                    :options="dizionariCode"
                    placeholder="seleziona tipo"
                  /><!-- :reduce="cod => cod.codice" -->
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group
                label="Nome Codice"
                label-for="product-cod-add-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome Codice"
                  :rules="'required'"
                >
                  <b-form-input
                    id="product-cod-add-name"
                    v-model="formObject.nameCode"
                    :type="'text'"
                    name="nameCode"
                    placeholder="codice qr prodotto 1"
                  />
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <b-form-group
                label="Codice"
                label-for="product-cod-add-code"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Codice"
                  :rules="formObject.type.codice === 'Ean8' ? { required: true, length: 8, regex: /\b(?:\d{8})\b/ } : ''"
                >
                  <b-form-input
                    id="product-cod-add-code"
                    v-model="formObject.code"
                    :type="formObject.type.codice === 'Ean8' ? 'number' : 'text'"
                    name="codice"
                    placeholder="Codice"
                    @input="checkValidation"
                  />
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Fornitore"
                label-for="product-code-productor"
                label-class="font-weight-bolder"
              >
                <v-select
                  id="product-code-type-add"
                  v-model="formObject.productor"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descrizione"
                  :reduce="cod => ({ id: cod.id })"
                  :options="optionProductors"
                  placeholder="seleziona fornitore"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <div
                v-if="formObject.type.codice !== 'QRCode' && formObject.type.codice !== 'DataMatrix' && formObject.type.codice !== 'others' && dizionariCode.some(e => e.codice === formObject.type.codice)"
                class="barcodeStyle m-auto"
              >
                <ejs-barcodegenerator
                  id="barcode"
                  ref="barcodeControl"
                  :invalid="invalidInput"
                  :width="width"
                  :height="height"
                  :type="formObject.type.codice"
                  :value="formObject.code"
                  :mode="'SVG'"
                />
              </div>
              <div
                v-if="formObject.type.codice === 'QRCode'"
                class="barcodeStyle m-auto"
              >
                <ejs-qrcodegenerator
                  id="barcode"
                  ref="barcodeControl"
                  :width="width"
                  :display-text="'displaytext'"
                  :height="height"
                  :value="formObject.code"
                  :mode="'SVG'"
                />
              </div>
              <div
                v-if="formObject.type.codice === 'DataMatrix'"
                class="barcodeStyle m-auto"
              >
                <ejs-datamatrixgenerator
                  id="barcode"
                  ref="barcodeControl"
                  :width="width"
                  :display-text="'displaytext'"
                  :height="height"
                  :value="formObject.code"
                  :mode="'SVG'"
                />
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BRow, BCol, BModal, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { BarcodeGeneratorPlugin, QRCodeGeneratorPlugin, DataMatrixGeneratorPlugin } from '@syncfusion/ej2-vue-barcode-generator'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import axios from 'axios'

Vue.use(BarcodeGeneratorPlugin)
Vue.use(QRCodeGeneratorPlugin)
Vue.use(DataMatrixGeneratorPlugin)
Vue.use(ToastPlugin)

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    /* codeToEdit: {
      type: Object,
      required: false,
      default: (() => ({
        id: '',
        value: '',
        code: '',
        type: '',
        nameCode: '',
        productor: '',
      })),
    }, */
    dizionariCode: { type: Array, required: true },
  },
  data() {
    return {
      required,
      formObject: {
        id: 0,
        code: '',
        type: {},
        productor: { id: 0 },
      },
      canShowError: false,
      width: '200px',
      height: '200px',
      value: '',
      code: '',
      productor: '',
      nameCode: '',
      optionProductors: [
        { codice: 1, descrizione: 'Default', id: 1 },
      ],
    }
  },
  methods: {
    resetModal() {
      this.formObject = {
        id: 0,
        code: '',
        type: {},
        productor: '',
      }
    },
    async addCode(bvModalEvt) {
      // console.log(this.$store.state.products)
      const productId = this.$store.state.products.productGeneral.id
      // console.log(productId)
      const code = {
        id: this.formObject.id, prodotto: { id: productId }, fornitore: { id: this.formObject.productor.id }, tipoCodice: { id: this.formObject.type.id }, codice: this.formObject.code, // this.formObject.id
      }
      // console.log('codiceee', code)
      bvModalEvt.preventDefault()
      // aggiungere controllo dopo prevent default e prima di $nextTick
      await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([{ ...code }]),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Prodotto',
            metodo: 'setProdottoCodici',
            ACL: 'invoice-list',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok', res)
          const newCode = {
            id: res.data.codiceProdotto.id,
            codice: res.data.codiceProdotto.codice,
            tipoCodice: [{ id: res.data.codiceProdotto.id_tipo_codice }],
            fornitore: { id: this.formObject.productor.id },
          }
          const allCodes = this.$store.getters['products/getProductCodici']
          allCodes.unshift(newCode)
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Codice/i Aggiunto/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
          })
          // this.$store.dispatch('products/setProductCodici', allCodes)
        }
        // this.$emit('emitAddProduct', code)
      }).catch(e => e)
      this.$nextTick(() => {
        this.$refs['add-productc-code'].toggle('#toggle-btn')
      })
    },
    checkValidation() {
      //  console.log(this.$refs.barcodeControl.ej2Instances)
      //  const valid = this.checkEan(this.formObject.code)
      // console.log('valid', valid)
    },
    invalidInput() {
      // console.log(args)
      // console.log('ciao')
    },
    checkEan(code) {
      // console.log(code)
      return (10 - (code.split('').reduce((s, e, i) => s + parseInt(e, 10) * ((i % 2 === 0) ? 1 : 3), 0) % 10)) % 10
      /* const sum = code.split('').reverse().reduce((sum1, char, idx) => {
        const digit = Number(char)
        const weight = (idx + 1) % 2 === 0 ? 1 : 3
        const partial = digit * weight
        return sum1 + partial
      }, 0)
      const remainder = sum % 10
      const checksum = remainder ? (10 - remainder) : 0
      return checksum */
    },
  },
}
</script>

<style>

</style>
