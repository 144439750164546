<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <!-- <p>{{ dizionarioCodici }}</p>
    <br>
    <p>codShowed {{ codShowed }}</p> -->
    <add-cod-product
      ref="add-product-code"
      :dizionari-code="dizionarioCodici"
    /> <!-- :code-to-edit="codeToEdit" -->
    <b-form
      @submit.prevent="onSubmit"
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
          lg="4"
          class="my-2"
        >
          <div
            v-b-modal.add-productc-code
            class="border border-primary rounded justify-content-center d-flex flex-column m-auto"
            style="height: 328px !important;"
          >
            <b-avatar
              variant="info"
              size="lg"
              class="justify-content-center d-flex mx-auto"
            >
              <feather-icon
                icon="PlusIcon"
                size="30"
              />
            </b-avatar>
            <h6 class="text-center mt-1">
              Aggiungi <span class="d-block">Codice</span>
            </h6>
          </div>
        </b-col>
        <b-col
          v-for="(cod) in codShowed"
          :key="cod.id"
          cols="12"
          sm="6"
          lg="4"
          class="my-2"
        > <!-- {{ cod }}
          {{ cod.tipoCodice.concat('-').concat(cod.codice) }} -->
          <div
            v-if="cod.tipoCodice !== 'QRCode' && cod.tipoCodice !== 'DataMatrix'"
            class="d-flex flex-column border border-primary rounded p-2"
          >
            <div class="d-flex mb-2">
              <b-badge
                v-b-tooltip.hover
                :title="cod.tipoCodice.concat('-').concat(cod.codice).toUpperCase()"
                variant="primary"
                class="m-auto"
              >
                {{ cod.tipoCodice.concat('-').concat(cod.codice).toUpperCase() }}
              </b-badge>
            </div>
            <div
              class="barcodeStyle m-auto"
            >
              <ejs-barcodegenerator
                :id="cod.id"
                ref="barcodeControl"
                :width="width"
                :height="height"
                :type="cod.tipoCodice"
                :value="cod.codice"
                :mode="mode"
              />
            </div>
            <div class="d-flex justify-content-center mt-2">
              <b-dropdown
                style="width: 70px!important"
                class="mr-1"
              >
                <template #button-content>
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </template>
                <b-dropdown-item @click="exportCode(cod.id, cod.tipoCodice.concat('-').concat(cod.codice), 'JPG')">
                  JPG</b-dropdown-item>
                <b-dropdown-item @click="exportCode(cod.id, cod.tipoCodice.concat('-').concat(cod.codice), 'PNG')">
                  PNG</b-dropdown-item>
              </b-dropdown>
              <b-button
                style="width: 70px !important"
                @click="deleteCode(cod)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="m-auto text-center"
                />
              </b-button>
            </div>
          </div>
          <div
            v-if="cod.tipoCodice === 'QRCode'"
            class="d-flex flex-column border border-primary rounded p-2"
          >
            <div class="d-flex mb-2">
              <b-badge
                v-b-tooltip.hover
                :title="cod.tipoCodice.concat('-').concat(cod.codice).toUpperCase()"
                variant="primary"
                class="m-auto"
              >
                {{ cod.tipoCodice.concat('-').concat(cod.codice).toUpperCase().substring(0, 30) }}
              </b-badge>
            </div>
            <div
              class="barcodeStyle m-auto"
            >
              <ejs-qrcodegenerator
                :id="cod.id"
                ref="barcodeControl"
                :width="width"
                :display-text="'displaytext'"
                :height="height"
                :value="cod.codice"
                :mode="mode"
              />
            </div>
            <div class="d-flex justify-content-center mt-2">
              <b-dropdown
                class="mr-1"
                style="width: 70px!important"
              >
                <template #button-content>
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </template>
                <b-dropdown-item @click="exportCode(cod.id, cod.tipoCodice.concat('-').concat(cod.codice), 'JPG')">
                  JPG</b-dropdown-item>
                <b-dropdown-item @click="exportCode(cod.id, cod.tipoCodice.concat('-').concat(cod.codice), 'PNG')">
                  PNG</b-dropdown-item>
              </b-dropdown>
              <b-button
                style="width: 70px !important"
                @click="deleteCode(cod)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="m-auto text-center"
                />
              </b-button>
            </div>
          </div>
          <div
            v-if="cod.tipoCodice === 'DataMatrix'"
            class="d-flex flex-column border border-primary rounded p-2"
          >
            <div class="d-flex mb-2">
              <b-badge
                v-b-tooltip.hover
                :title="cod.tipoCodice.concat('-').concat(cod.codice).toUpperCase()"
                variant="primary"
                class="m-auto"
              >
                {{ cod.tipoCodice.concat('-').concat(cod.codice).toUpperCase() }}
              </b-badge>
            </div>
            <div
              class="barcodeStyle m-auto my-2"
            >
              <ejs-datamatrixgenerator
                :id="cod.id"
                ref="barcodeControl"
                :width="width"
                :displatext="'displaytext'"
                :height="height"
                :value="cod.codice"
                :mode="mode"
              />
            </div>
            <div class="d-flex justify-content-center mt-2">
              <b-dropdown
                class="mr-1"
                style="width: 70px!important"
              >
                <template #button-content>
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </template>
                <b-dropdown-item @click="exportCode(cod.id, cod.tipoCodice.concat('-').concat(cod.codice), 'JPG')">
                  JPG</b-dropdown-item>
                <b-dropdown-item @click="exportCode(cod.id, cod.tipoCodice.concat('-').concat(cod.codice), 'PNG')">
                  PNG</b-dropdown-item>
              </b-dropdown>
              <b-button
                style="width: 70px !important"
                @click="deleteCode(cod)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="m-auto text-center"
                />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Vue from 'vue'
import {
  BRow, BCol, BForm, BButton, BCard, BDropdown, BDropdownItem, BBadge, BAvatar, VBTooltip, // BCardText, BCardTitle, BCardBody, BMediaBody, BMediaAside, BAvatar, BMedia, // BFormGroup, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { BarcodeGeneratorPlugin, QRCodeGeneratorPlugin, DataMatrixGeneratorPlugin } from '@syncfusion/ej2-vue-barcode-generator'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import AddCodProduct from '@/views/pages/modal/AddCodProduct.vue'

Vue.use(BarcodeGeneratorPlugin)
Vue.use(QRCodeGeneratorPlugin)
Vue.use(DataMatrixGeneratorPlugin)
Vue.use(ToastPlugin)

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BFormGroup,
    // BFormInput,
    BForm,
    BButton,
    AddCodProduct,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    // BImg,
    /* BCardText,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BMedia, */
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    /* propertyDataProp: {
      type: Object,
      required: true,
    }, */
    dizionarioCodici: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      codes: [],
      codeToEdit: {
        value: '',
        code: '',
        nameCode: '',
        productor: '',
      },
      barCodeInstance: '',
      width: '200px',
      height: '200px',
      type: 'QRCode',
      value: '123456789',
      mode: 'SVG',
      taxation: [
        { value: '22 %' },
        { value: '10 %' },
        { value: '5 %' },
        { value: 'No Iva' },
      ],
      typeCod: [
        { text: 'EAN-8', type: 'Ean8' },
        { text: 'CODE 128', type: 'Code128' },
        { text: 'CODE 128 A', type: 'Code128A' },
        { text: 'CODE 128 B', type: 'Code128B' },
        { text: 'CODE 128 C', type: 'Code128C' },
        { text: 'CODE 93', type: 'Code93' },
        { text: 'CODE 32', type: 'Code32' },
        { text: 'Codabar', type: 'Codabar' },
        { text: 'EAN-13', type: 'Ean13' },
        { text: 'UPC-A', type: 'UpcA' },
        { text: 'UPC-E', type: 'UpcE' },
        { text: 'Code39', type: 'Code39' },
        { text: 'Code39 Extended', type: 'Code39Extension' },
        { text: 'QRCode', type: 'QRCode' },
        { text: 'Data Matrix', type: 'DataMatrix' },
        { text: 'Codice Fornitore/Produttore', type: 'others' },
      ],
      mediaData: [
        { avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM' },
        { avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City' },
      ],
    }
  },
  computed: {
    /* propertyData() {
      this.propertyDataProp.productCod.forEach(element => {
        Vue.set(element, 'editing', false)
      })
      return this.propertyDataProp.productCod
    }, */
    /* codiciProdotto() {
      return this.$store.state.products.productCodici
    }, */
    codShowed() {
      // console.log('store', this.$store.state.products.productCodici)
      return this.$store.state.products.productCodici.map(el => {
        const element = {
          codice: el.codice,
          id: el.id,
          // fornitore: { ...el.fornitore[0].id },
          tipoCodice: this.dizionarioCodici.filter(ele => ele.id === el.tipoCodice[0].id).reduce(elem => elem.codice).codice,
          productor: el.fornitore,
          idCodice: el.tipoCodice[0].id,
        }
        // console.log('el', element)
        return element
      })
    },
  },
  mounted() {
    /* this.barCodeInstance = this.$refs.barcodeControl.ej2Instances
    this.barCodeInstance.displayText.margin.top = 10 */
  },
  methods: {
    deleteCode(cod) {
      // console.log('coood', cod)
      axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([{ id: cod.id }]),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-ProdottoCodice',
            metodo: 'deleteProdottoCodici',
            ACL: 'invoice-list',
          },
        }).then(res => {
        if (res.data.esito === 'OK') {
          // console.log(res)
          const { id } = res.data.prodottoCodici[0]
          // console.log(id)
          this.$store.dispatch('products/deleteProductCode', id)
          // console.log(this.$store.getters['products/getProductCodici'])
          // eliminare id con vuex
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Codice Eliminato Correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        }
      }).catch(e => e)
    },
    editCode(code) {
      // console.log(code)
      const findIndex = this.propertyData.findIndex(el => el.id === code.id)
      if (findIndex >= 0) {
        this.propertyData[findIndex].editing = true
      }
    },
    exportCode(id, filename, type) {
      let barcodeInstance = {}
      const barcodeObj = document.getElementById(id)
      // eslint-disable-next-line prefer-destructuring
      barcodeInstance = barcodeObj.ej2_instances[0]
      // const filename = id
      /* console.log(barcodeInstance)
      console.log(barcodeObj)
      console.log(id) */
      barcodeInstance.exportImage(filename, type)
    },
    onSubmit() {},
  },
}
</script>

<style>
.barcodeStyle {
        max-height: 200px;
        max-width: 200px;
        padding-left: 0%;
        padding-top: 0%;
    }

  .codiciContainerStyle {
    border: 1px solid #35a3c6;
    border-radius: 20px;
  }
</style>
