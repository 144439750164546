import { render, staticRenderFns } from "./SidebarProductEventHandlerInDetailsProdcut.vue?vue&type=template&id=3ef1aaba&"
import script from "./SidebarProductEventHandlerInDetailsProdcut.vue?vue&type=script&lang=js&"
export * from "./SidebarProductEventHandlerInDetailsProdcut.vue?vue&type=script&lang=js&"
import style0 from "./SidebarProductEventHandlerInDetailsProdcut.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports