<template>
  <b-tabs
    v-model="currentTab"
    vertical
    pills
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <!-- <feather-icon
          icon="UserIcon"
          size="18"
        /> -->
        <img
          :src="currentTab !== 0 ? require('@/@core/assets/icone-light-dark/product-details/general.svg') : require('@/@core/assets/icone-light-dark/product-details/general-dark.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span class="font-weight-bold">Generali</span>
      </template>

      <DetailsGeneral
        :dizionario-iva="dizionari.aliquotaIvaOption"
        :dizionario-tipo-prodotto="dizionari.tipoProdottoOption"
        :dizionario-categorie="dizionari.categorieOption"
        :dizionario-unita-misura="dizionari.unitaMisura"
        :dizionario-unita-utilizzo="dizionari.unitaUtilizzo"
        :dizionario-set-attributo="dizionari.setAttributoOption"
        :product-general="product.general"
      />
    </b-tab>
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <img
          :src="currentTab !== 1 ? require('@/@core/assets/icone-light-dark/product-details/codici.svg') : require('@/@core/assets/icone-light-dark/product-details/codici-dark.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span class="font-weight-bold">Codici</span>
      </template>

      <DetailsProperty
        :dizionario-codici="dizionari.codici"
      />
    </b-tab>
    <!-- info -->
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <img
          :src="currentTab !== 2 ? require('@/@core/assets/icone-light-dark/product-details/caratteristiche.svg') : require('@/@core/assets/icone-light-dark/product-details/caratteristiche-dark.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span class="font-weight-bold">Caratteristiche</span>
      </template>

      <DetailsFisic />
    </b-tab>
    <!-- <b-tab lazy>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
        />
        <span class="font-weight-bold">SetAttributi</span>
      </template>

      <DetailsSpecific />
    </b-tab> -->
    <b-tab>

      <!-- title -->
      <template #title>
        <img
          :src="currentTab !== 3 ? require('@/@core/assets/icone-light-dark/product-details/magazzino.svg') : require('@/@core/assets/icone-light-dark/product-details/magazzino-dark.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span class="font-weight-bold">Magazzino</span>
      </template>

      <DetailsWarehouse
        :warehouse-data="options.warehouse"
      />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <img
          :src="currentTab !== 4 ? require('@/@core/assets/icone-light-dark/product-details/prezzi-listini.svg') : require('@/@core/assets/icone-light-dark/product-details/prezzi-listini-dark.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span class="font-weight-bold">Prezzi e Listini</span>
      </template>

      <DetailsPrices />
    </b-tab>
    <!-- <b-tab lazy>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">SEO</span>
      </template>

      <DetailsSEO />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Marketing</span>
      </template>

      <DetailsMarketing />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Fornitore</span>
      </template>

      <DetailsSupplier />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Commissioni TODO</span>
      </template>
      <DetailsCommission />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Recensioni TODO</span>
      </template>
      <DetailsReviews />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import axios from 'axios'
import DetailsFisic from './DetailsFisic.vue'
import DetailsGeneral from './DetailsGeneral.vue'
import DetailsWarehouse from './DetailsWarehouse.vue'
import DetailsProperty from './DetailsProperty.vue'
// import DetailsSEO from './DetailsSEO.vue'
// import DetailsReviews from './DetailsReviews.vue'
import DetailsPrices from './DetailsPrices.vue'
// import DetailsPhotoVideo from './DetailsPhotoVideo.vue'
// import DetailsSpecific from './DetailsSpecific.vue'
// import DetailsMarketing from './DetailsMarketing.vue'
// import DetailsCommission from './DetailsCommission.vue'
// import DetailsSupplier from './DetailsSupplier.vue'
// import { getProdotto } from '@/@core/api-service/catalog/products'

export default {
  components: {
    BTabs,
    BTab,
    DetailsFisic,
    DetailsGeneral,
    DetailsProperty,
    DetailsWarehouse,
    // DetailsSEO,
    // DetailsReviews,
    DetailsPrices,
    // DetailsPhotoVideo,
    // DetailsSpecific,
    // DetailsMarketing,
    // DetailsCommission,
    // DetailsSupplier,
  },
  data() {
    return {
      currentTab: 0,
      options: {},
      product: {
        general: {},
        id: 0,
        attivo: 0,
        disponibile: 0,
        visibile: 0,
        inEvidenza: 0,
        codice: '',
        nome: '',
        tipoProdotto: '',
        unitaMisura: '',
        unitaUtilizzo: '',
        setAttributi: '',
        categorie: [],
        statoServizio: '',
        note: '',
        aliquotaIva: '',
        prezzoVendita: '',
        costo: '',
        descrizioneBreve: '',
        caratteristiche: {},
      },
      dizionari: {
        aliquotaIvaOption: [],
        categorieOption: [],
        setAttributoOption: [],
        tipoProdottoOption: [],
        unitaMisura: [],
        codici: [],
        unitaUtilizzo: [],
      },
    }
  },
  beforeMount() {
    this.options = {
      general: {
        // eslint-disable-next-line global-require
        img: require('@/assets/images/prodottiVET/croccantini.jpg'),
        // img: '/assets/images/prodottiVET/croccantini.jpg',
        active: false,
        name: 'Croccantini',
        codProduct: '0001',
        shortDescription: 'Croccantini vegani per cani diabetici',
        type: 'Servizio',
        categories: ['General', 'Mangimi', 'Consumabili'],
        subCategories: ['sub-1', 'sub-2', 'sub-3'],
        unit: 'kg',
        quantity: '2',
        price: [{ name: 'Privati', price: 10 }, { name: 'Aziende', price: 9 }, { name: 'Partiners', price: 8.5 }],
        longDescription: '',
      },
      property: {
        productCod: [
          /* {
            id: 1, value: 'Matrice', code: '0101010101', type: 'DataMatrix', nameCode: 'prova1',
          },
          {
            id: 2, value: 'QRCode', code: '1olpdsame2', type: 'QRCode', nameCode: 'prova1',
          },
          {
            id: 3, value: 'Cod SDI', code: '129403', type: 'Code128', nameCode: 'prova1',
          },
          {
            id: 4, value: 'Cod a barre2', code: '0101010101121231', type: 'Code128A', nameCode: 'prova1',
          },
          {
            id: 5, value: 'Ean8', code: '50123452', type: 'Ean8', nameCode: 'prova1',
          }, */
        ],
        iva: { value: '22 %' },
        link: 'www.google.com',
        producer: 'VETPET',
      },
      fisic: {
        dimension: [
          {
            module: 'Larghezza',
            netto: '0.70',
          },
          {
            module: 'Altezza',
            netto: '0.40',
          },
          {
            module: 'Profondita',
            netto: '0.30',
          },
        ],
        weight: [
          {
            netto: 10,
            lordo: 11.5,
          },
        ],
      },
      warehouse: {
        quantity: 18,
      },
    }
    // this.$store.dispatch('products/setproductGeneral', this.options)
  },
  async mounted() {
    await this.getCombo()
    // console.log('dopo combo')
    await this.getProdotto()
  },
  methods: {
    async getProdotto() {
      // JSON.stringify([{ id: this.$route.params.id }])
      await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([{ id: this.$route.params.id }]),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Prodotto',
            metodo: 'getProdotto',
            ACL: '',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          const response = { ...res.data }
          const productGeneral = { ...res.data.prodottoGenerale }
          const categorie = [...new Set(productGeneral.categorie.map(item => item.id_categoria))].map(el => ({ id: el }))
          productGeneral.categorie = [...categorie]
          // console.log('sono su ok prodotto', productGeneral)
          this.product.general = { ...productGeneral }
          this.product.codici = Object.prototype.hasOwnProperty.call(response, 'prodottoCodici') ? [...res.data.prodottoCodici] : [] // [...res.data.prodottoCodici]
          this.product.caratteristiche = Object.prototype.hasOwnProperty.call(response, 'prodottoCaratteristiche') ? { ...res.data.prodottoCaratteristiche } : {} // [...res.data.prodottoCodici]
          this.$store.dispatch('products/setProductGeneral', this.product.general)
          this.$store.dispatch('products/setProductGeneralReset', this.product.general)
          /* if (!Object.prototype.hasOwnProperty.call(this.product.caratteristiche, 'prodottoAssociati')) {
            this.product.caratteristiche.prodottiAssociati = []
          } */
          this.$store.dispatch('products/setProductCaratteristiche', this.product.caratteristiche)
          this.$store.dispatch('products/setProductCodici', this.product.codici)
          // console.log(this.dizionari.tipoProdottoOption)
          // console.log(this.product.general.tipo)
          // const typeProduct = this.dizionari.tipoProdottoOption.find(el => el.id === this.product.general.tipoProdotto.id)
          // console.log(typeProduct)
          // if (Object.prototype.hasOwnProperty.call(typeProduct, 'id')) this.$store.dispatch('products/setTypeProduct', typeProduct)
        }
      })
    },
    async getCombo() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Diz-Dizionari',
            metodo: 'getDizionariCombo',
            ACL: '',
          },
        }).then(res => {
        // console.log('getDizionariCombo', res)
        if (res.data.esito === 'OK') {
          console.log('getDizionariCombo', res)
          const {
            aliquotaIva, categoria, setAttributo, tipoProdotto, unitaMisura, tipoCodice, unitaUtilizzo,
          } = res.data
          this.dizionari.aliquotaIvaOption = aliquotaIva
          this.dizionari.categorieOption = categoria
          this.dizionari.setAttributoOption = setAttributo
          this.dizionari.tipoProdottoOption = tipoProdotto
          this.dizionari.unitaMisura = unitaMisura
          this.dizionari.codici = tipoCodice
          this.dizionari.unitaUtilizzo = unitaUtilizzo
        }
      })
    },
  },
}
</script>
